.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.yandex-map > div {
  width: 100% !important;
  border-radius: 8px;
  overflow: hidden;
}

.textContent .ck .ck-content {
  min-height: 400px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.textContent .ck .ck-toolbar {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.blogContent .ck .ck-content {
  min-height: 170px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.blogContent .ck .ck-toolbar {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
iframe {
  display: none !important;
}
